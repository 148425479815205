import React from "react"
import Swiper from "react-id-swiper"

const StaticCustomDesign = () => {
  const params = {
    slidesPerView: 2,
    spaceBetween: 10,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
  }
  return (
    <div>
      <Swiper {...params}>
        <div>
          <div style={{ height: 1000, background: "#E5E5E5" }}></div>
        </div>
        <div>
          <div style={{ height: 1000, background: "#E5E5E5" }}></div>
        </div>
      </Swiper>
    </div>
  )
}

export default StaticCustomDesign
