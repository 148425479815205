import React from "react"

import Layout from "../templates/Page"
import Footer from "../components/ContentBuilder/Footer"
import SwiperList from "../components/ContentBuilder/SwiperList"
import StaticSwiperImageList from "../components/ContentBuilder/SwiperImageList/StaticSwiperImageList"
import Header from "../components/ContentBuilder/Header"
import StaticCustomDesign from "../components/ContentBuilder/SwiperList/StaticCustomDesign"
import Subscribe from "../components/Footer/subscribe"

const HomeDesignDetailPage = () => {
  const groundFloors = [
    "Home Office",
    "Media Room",
    "Infant Room",
    "Living Room",
  ]
  const levels = ["Home Office", "Media Room", "Infant Room", "Living Room"]

  return (
    <Layout hideHeader>
      <Header isLogin />
      <div className="container">
        <div className="mobile-enable c-home-design">
          <StaticSwiperImageList/>
          <div className="d-flex justify-content-between align-items-center wrap-section mt-30 mb-30 border-bottom-cell">
            <div className="product-name-title">WILLOWDALE 1 asdf</div>
          </div>
          <div className="d-flex justify-content-center align-items-center wrap-section mt-30 mb-30 ">
            <div className="c-home-design-button">
              <div className="c-home-design-button-text">
                START PERSONALISING
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center wrap-section mt-30 mb-30 ">
            <div className="c-home-design-button-icon">
              <div className="c-home-design-button-text">Add to board</div>
            </div>
          </div>
          <div className="c-home-design-line"></div>
          <div className="mt-30 mb-30 c-home-design-information">
            <div className="d-flex justify-content-between c-home-about">
              ABOUT
            </div>
            <div className="d-flex justify-content-between c-home-about-text mt-15">
              It will ultimately serve as a welcome addition to any block size.
              This floor plan boasts space, room for entertainment.
            </div>
          </div>
          <div className="mt-30 mb-30 c-home-design-information">
            <div className="d-flex justify-content-between border-bottom-cell">
              <div className="c-home-information-text">Price from</div>
              <div className="c-home-information-value bold">$268,900.00</div>
            </div>
            <div className="d-flex justify-content-between mt-15 mb-15 border-bottom-cell">
              <div className="c-home-information-text">Designer</div>
              <div className="c-home-information-value">Harriet Issac Cole</div>
            </div>
            <div className="d-flex justify-content-between border-bottom-cell">
              <div className="c-home-information-text">Approx House area</div>
              <div className="c-home-information-value">180 sqm</div>
            </div>
          </div>
          <div className="c-home-design-information d-flex justify-content-center align-items-center wrap-section mt-30 mb-30 ">
            <div className="c-home-information-text d-flex justify-content-center">
              View More
            </div>
            <div className="d-flex justify-content-center mt-15">
              <img src="/img/chevron-circle-down-solid.svg" />
            </div>
          </div>
          <SwiperList title="Other land this home works with" navClass="land" />
          <SwiperList title="Other homes that fit this land" navClass="home" />
          <SwiperList title="Available facade options" navClass="facade" />
          <SwiperList title="Works with these styles" navClass="works" />
          <SwiperList title="Related items" navClass="related" />
        </div>
        <div className="mobile-disable">
          <div className="d-none d-sm-flex justify-content-between align-items-center wrap-section mt-30 mb-30 border-bottom-cell">
            <div className="product-name-title">WILLOWDALE 1 asdf2</div>
            <div className="d-flex align-items-center">
              <div className="w-100 mr-4">Add to board</div>
              <img src="/img/hearth.svg" />
            </div>
          </div>
          <div className="wrap-section mt-3 mb-3 home-detail-title">
            Information
          </div>
          <div className="wrap-section border-top-cell border-bottom-cell mt-15 pt-2 pb-2 d-flex align-items-center">
            <div className="d-flex align-items-center mr-5 mt-15 mb-15">
              <div className="title-cell">Price from</div>
              <div className="ml-5 right-cell">$268,900.00</div>
            </div>
            <div className="ml-5 mr-5 border-left-cell"></div>
            <div className="d-flex align-items-center mr-5 mt-15 mb-15">
              <div className="title-cell">Designer</div>
              <div className="ml-5 right-cell">Harriet Issac Cole</div>
            </div>
            <div className="ml-5 mr-5 border-left-cell"></div>
            <div className="d-flex align-items-center mr-5 mt-15 mb-15">
              <div className="title-cell">Approx House area</div>
              <div className="ml-5 right-cell">180 sqm</div>
            </div>
            <div className="ml-5 mr-5 border-left-cell"></div>
            <div className="d-flex align-items-center mr-5 mt-15 mb-15">
              <div className="title-cell">Designed for lots</div>
              <div className="ml-5 right-cell">12 x 28.5m</div>
            </div>
          </div>

          <div className="d-flex wrap-section mt-5 mb-3 pb-4 border-bottom-cell">
            <div className="home-detail-title">Customise Design</div>
          </div>
          <div className="wrap-section row mt-5">
            <div className="col-sm-3">
              <div className="title-custom-design border-bottom pb-3">
                Ground floor options
              </div>
              {groundFloors.map((item, idx) => (
                <div key={`gf${idx}`} className="form-check mt-3 mb-3">
                  <input type="checkbox" className="form-check-input" />
                  <label className="form-check-label" htmlFor="exampleCheck1">
                    {item}
                  </label>
                </div>
              ))}

              <div className="title-custom-design border-bottom pb-3 mt-5">
                Level options
              </div>
              {levels.map((item, idx) => (
                <div key={`levels${idx}`} className="form-check mt-3 mb-3">
                  <input type="checkbox" className="form-check-input" />
                  <label className="form-check-label" htmlFor="exampleCheck1">
                    {item}
                  </label>
                </div>
              ))}

              <button className="btn btn-quote w-100 mt-5">
                <div className="btn-text">Save</div>
              </button>

              <div className="d-flex mt-3">
                <img src="/img/quoteBlack.svg" />
                <div className="border-quote-bottom ml-3" />
              </div>

              <div className="mt-3">
                Life on your terms, A home built by you, for you{" "}
              </div>

              <div className="title-custom-design border-bottom pb-3 mt-5">
                Gallery
              </div>
              <div className="d-flex mt-5 justify-content-around">
                <div
                  style={{ height: 65, width: 65, background: "#E5E5E5" }}
                ></div>
                <div
                  style={{ height: 65, width: 65, background: "#E5E5E5" }}
                ></div>
                <div
                  style={{ height: 65, width: 65, background: "#E5E5E5" }}
                ></div>
                <div
                  style={{ height: 65, width: 65, background: "#E5E5E5" }}
                ></div>
              </div>
            </div>
            <div className="col-sm-9">
              <StaticCustomDesign />
            </div>
          </div>
          <SwiperList title="Other land this home works with" navClass="land" />
          <SwiperList title="Other homes that fit this land" navClass="home" />
          <SwiperList title="Available facade options" navClass="facade" />
          <SwiperList title="Works with these styles" navClass="works" />
          <SwiperList title="Related items" navClass="related" />
        </div>
        
      </div>
      <Subscribe></Subscribe>
    </Layout>
  )
}

export default HomeDesignDetailPage
