import React from "react"
import Swiper from "react-id-swiper"

const StaticSwiperImageList = () => {
  const params = {
    slidesPerView: "auto",
  }

  return (
    <div className="swiper-list-wrap">
      <div>
        <Swiper {...params}>
          <div className="itemSlider-large"></div>
          <div className="itemSlider-large"></div>
          <div className="itemSlider-large"></div>
          <div className="itemSlider-large"></div>
          <div className="itemSlider-large"></div>
          <div className="itemSlider-large"></div>
          <div className="itemSlider-large"></div>
          <div className="itemSlider-large"></div>
        </Swiper>
      </div>
    </div>
  )
}

export default StaticSwiperImageList
